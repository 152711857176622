//eslint-disable
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _map from 'lodash.map';
import _get from 'lodash.get';

import { imageBase } from '../../constants';

const ThumbnailsTrack = styled.ul`
  height: 40px;
  margin: 25px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 0;
`;
const Thumbnail = styled.li`
  list-style-type: none;
  transition: all 300ms;
  height: 40px;
  margin: 0 10px 0 0;
  a {
    min-height: 40px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    img {
      height: 40px;
    }
    span {
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.7);
    }
  }
  &:hover {
    transform: translate3d(-1px, -1px, 0);
    opacity: 0.8;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.25);
  }
`;

const PlayButton = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 300ms;
  &:hover {
    opacity: 0.8;
  }
`;

export const SliderThumbnails = ({
  images,
  videos,
  work,
  setCurrentImage,
  setCurrentVideo,
}) => {
  const getSliderImagePaths = () => {
    return _map(images, image => {
      if (image.zoomable) {
        return `${imageBase}${_get(work, 'gridImagelowres')}`;
      }
      return `${imageBase}${_get(image, 'image_1')}`;
    });
  };

  const renderImageThumbnails = () => {
    const imagePaths = getSliderImagePaths();

    return _map(imagePaths, (imagePath, index) => (
      <Thumbnail key={imagePath} onClick={() => setCurrentImage(index)}>
        <a>
          <img src={imagePath} />
        </a>
      </Thumbnail>
    ));
  };

  const renderVideoThumbnails = () => {
    if (videos.length === 0) return null;

    return _map(videos, video => (
      <Thumbnail
        key={video.vimeoId}
        onClick={() => setCurrentVideo(video.vimeoId)}
      >
        <a>
          <img src={video.thumbnail} />
          <span>video</span>
          <PlayButton src={`${imageBase}play-button.svg`} />
        </a>
      </Thumbnail>
    ));
  };

  return (
    <ThumbnailsTrack>
      {renderImageThumbnails()}
      {renderVideoThumbnails()}
    </ThumbnailsTrack>
  );
};

SliderThumbnails.propTypes = {
  images: PropTypes.array,
  videos: PropTypes.array,
  work: PropTypes.object,
  setCurrentImage: PropTypes.func,
  setCurrentVideo: PropTypes.func,
};

export default SliderThumbnails;
